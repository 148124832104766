import React, {useEffect, useState} from 'react';
import {
    Alert,
    Avatar,
    Box,
    Breadcrumbs,
    ButtonBase,
    Card,
    CardActionArea,
    CardMedia,
    Container,
    FormControl, FormControlLabel,
    InputLabel,
    LinearProgress,
    Link,
    MenuItem,
    Select, styled, Switch,
    TextField,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import SecurityUtils from "../component/SecurityUtils";
import {useTheme} from "@mui/material/styles";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import {AddAPhoto, Dashboard as DashboardIcon, ManageAccounts, Store} from "@mui/icons-material";
import Utils from "../component/Utils";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ShopHome = (props) => {

    const {id} = useParams();
    const {t, i18n} = useTranslation(['shop', 'admin']);
    const theme = useTheme();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        nameError: false,
        nameValidate: ''
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        currency: i18n.language === 'en' ? 'SGD' : 'CNY',
        logo: '',
        cover: '',
        profile: '',
        published: false
    });

    useEffect(() => {
        if (id) {
            axios.get(Utils.getApiUrl() + "/home/shop/" + id, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        for (const [name, value] of Object.entries(response.data)) {
                            if (model.hasOwnProperty(name)) {
                                setModel((values) => ({
                                    ...values, [name]: value
                                }));
                            }
                        }
                        setValues((values) => ({
                            ...values, loading: false
                        }))
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            setValues((values) => ({
                ...values, loading: false
            }))
        }
    }, []);

    const handleLogo = async (event) => {

        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append('logo', event.target.files[0]);

        await axios.post(Utils.getApiUrl() + "/home/shop/" + id + "/logo", formData, {
            headers: {
                "Authorization": SecurityUtils.getBearerToken(),
                "Content-Type": "multipart/form-data"
            }, processData: false, contentType: false,
            onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        }).then((response) => {
                console.log(response);
                setModel((values) => ({
                    ...values, logo: response.data.logo
                }));
                setValues((values) => ({
                    ...values, loading: false
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        )
    }

    const handleCover = async (event) => {

        setValues((values) => ({
            ...values, loading: true
        }));

        let formData = new FormData();
        formData.append('cover', event.target.files[0]);

        await axios.post(Utils.getApiUrl() + "/home/shop/" + id + "/cover", formData, {
            headers: {
                "Authorization": SecurityUtils.getBearerToken(),
                "Content-Type": "multipart/form-data"
            }, processData: false, contentType: false,
            onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        }).then((response) => {
                console.log(response);
                setModel((values) => ({
                    ...values, cover: response.data.cover
                }));
                setValues((values) => ({
                    ...values, loading: false
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        )
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setModel((values) => ({
            ...values, [name]: value
        }));
        if (name === "name") {
            if (value.trim().length > 1) {
                setValues((values) => ({
                    ...values, nameError: false, nameValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, nameError: true, nameValidate: t("shop:nameValidate")
                }));
            }
        }
    };

    const handleCheckbox = (event) => {
        let name = event.target.name;
        let value = event.target.checked;
        setModel((values) => ({
            ...values, [name]: value
        }));
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!(event.target.elements["name"].value.trim().length > 1)) {
            setValues((values) => ({
                ...values, nameError: true, nameValidate: t("shop:nameValidate")
            }));
            return false;
        }

        setValues((values) => ({
            ...values, loading: true
        }));

        if (model.id && model.id.trim().length > 0) {
            await axios.put(Utils.getApiUrl() + "/home/shop", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        SecurityUtils.refreshToken();
                        navigate('/info', {
                            state: {
                                alert: "success",
                                summary: t("messages.summary"),
                                details: t("messages.details")
                            }
                        });
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            await axios.post(Utils.getApiUrl() + "/home/shop", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        navigate('/info', {
                            state: {
                                alert: "success",
                                summary: t("messages.summary"),
                                details: t("messages.details")
                            }
                        });
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        }
    }

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("translation:nav.shop")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("translation:nav.shop")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"
                      sx={{display: 'flex', alignItems: 'center'}}>
                    <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                </Link>
                <Typography fontSize='inherit' sx={{display: 'flex', alignItems: 'center'}}>
                    <ManageAccounts sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.shop")}
                </Typography>
            </Breadcrumbs>

            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}
            <Box component="form" maxWidth="sm" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                {id && (
                    <Card sx={{my: 5, textAlign: 'center'}}>
                        <CardActionArea focusRipple sx={{
                            position: "relative",
                            overflow: "hidden",
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            '&:hover, &.Mui-focusVisible': {
                                '& .MuiCardActionArea-focusHighlight': {opacity: 0.5},
                                '& .MuiSvgIcon-root': {opacity: 1, zIndex: 1},
                                '& .MuiTypography-root': {opacity: 1, zIndex: 1}
                            },
                        }}>
                            <CardMedia component="div" sx={{
                                height: 200,
                                backgroundImage: model.cover ? "url(" + Utils.getApiUrl() + "/shop/" + model.cover
                                    + "?" + Math.random() + ")" : "url(/img/banner.jpg)",
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                color: theme.palette.common.white,
                                [theme.breakpoints.down('sm')]: {
                                    width: '100% !important', height: 150
                                }
                            }}>
                                <AddAPhoto fontSize='large' sx={{opacity: 0, mb: 1}}/>
                                <Typography component="span" sx={{opacity: 0}}>{t("user:updateCover")}</Typography>
                            </CardMedia>
                            <Box component='input' type="file" accept="image/*" onChange={handleCover} value="" sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                m: 0,
                                p: 0,
                                cursor: 'pointer',
                                opacity: 0,
                                zIndex: 10
                            }}/>
                        </CardActionArea>
                        <ButtonBase focusRipple sx={{
                            position: "relative", overflow: "hidden", mt: -6,
                            '&:hover, &.Mui-focusVisible': {
                                '& .MuiImageBackdrop-root': {opacity: 0.5},
                                '& .MuiImageMarked-root': {opacity: 1},
                            },
                        }}>
                            <Avatar sx={{bgcolor: theme.palette.primary.main, width: 120, height: 120}} alt={model.name}
                                    src={model.logo && Utils.getApiUrl() + "/shop/" + model.logo + "?" + Math.random()}>
                                <Store sx={{fontSize: 100}}/>
                            </Avatar>
                            <Box component="span" className="MuiImageBackdrop-root" sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                m: 0,
                                p: 0,
                                backgroundColor: theme.palette.common.black,
                                borderRadius: '50%',
                                opacity: 0,
                                transition: theme.transitions.create('opacity'),
                            }}/>
                            <Box component="div" className="MuiImageMarked-root" sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                m: 0,
                                p: 0,
                                opacity: 0,
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                color: theme.palette.common.white
                            }}>
                                <AddAPhoto fontSize='large' sx={{mb: 1}}/>
                                <Typography component="span">{t("shop:updateLogo")}</Typography>
                            </Box>
                            <Box component='input' type="file" accept="image/*" onChange={handleLogo} value="" sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                m: 0,
                                p: 0,
                                cursor: 'pointer',
                                opacity: 0
                            }}/>
                        </ButtonBase>
                    </Card>
                )}

                <TextField name="name" label={t("shop:name")} margin="normal" variant="standard" fullWidth required
                           value={model.name} onChange={handleChange} error={values.nameError}
                           helperText={values.nameValidate}/>

                <FormControl variant="standard" margin="normal" fullWidth>
                    <InputLabel id="currency">{t("shop:currency")}</InputLabel>
                    <Select labelId="currency" name="currency" value={model.currency} label={t("shop:currency")}
                            onChange={handleChange}>
                        <MenuItem value="SGD">{t("shop:singaporeDollar")}</MenuItem>
                        <MenuItem value="CNY">{t("shop:chineseYuan")}</MenuItem>
                    </Select>
                </FormControl>

                <FormControlLabel label={t("shop:online")} labelPlacement="end"
                                  control={<IOSSwitch name="published" sx={{my: 3, mx: 1}} checked={model.published}
                                                      onChange={handleCheckbox}/>}/>

                <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                               sx={{my: 3}}>{t("admin:save")}</LoadingButton>
            </Box>
        </Container>
    );
};

export default ShopHome;
