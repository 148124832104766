import React, {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    InputAdornment,
    LinearProgress,
    OutlinedInput,
    Pagination,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Utils from "../component/Utils";
import {Helmet} from "react-helmet-async";
import {AutoAwesome, FindInPage, Home as HomeIcon, Search, SettingsAccessibility, Store} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import {NumericFormat} from "react-number-format";
import SecurityUtils from "../component/SecurityUtils";
import {useTheme} from "@mui/material/styles";

const ShopProducts = (props) => {

    const {id} = useParams();
    const {t} = useTranslation('product');
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        selectedTab: 1,
        params: '',
        page: undefined
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        currency: '',
        logo: '',
        cover: '',
        profile: '',
        published: false,
        views: 0
    });

    const handleTabChange = (event, tab) => {
        setValues((values) => ({
            ...values, selectedTab: tab
        }))
    };

    useEffect(() => {
        loadPage(location.pathname);
        setValues((values) => ({
            ...values, params: location.pathname
        }));
    }, [location.pathname]);

    const loadPage = async (params) => {
        if (id) {
            await axios.get(Utils.getApiUrl() + "/shop/" + id, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        for (const [name, value] of Object.entries(response.data)) {
                            if (model.hasOwnProperty(name)) {
                                setModel((values) => ({
                                    ...values, [name]: value
                                }));
                            }
                        }
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, message: error.response.data.message
                        }));
                    }
                );
            await axios.get(Utils.getApiUrl() + Utils.buildParams(params, "/shop/", id), {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        setValues((values) => ({
                            ...values, loading: false, page: response.data
                        }));
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            setValues((values) => ({
                ...values, loading: false
            }))
        }
    };

    const gotoPage = (event, number) => {
        let params = values.params;
        params = Utils.buildParams(params, "/page/", number);
        console.log(params);
        navigate(params);
    };

    const [filter, setFilter] = useState({
        name: "",
        priceFrom: "",
        priceTo: ""
    })

    const handleFilterChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter((values) => ({
            ...values, [name]: value,
        }));
    };

    useEffect(() => {
        if (!values.params) return;
        const timeOutId = setTimeout(() => {
            let params = values.params;
            params = Utils.buildParams(params, "/name/", filter.name);
            loadPage(params);
            setValues((values) => ({
                ...values, params: params
            }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filter.name]);

    return (<>
            <Helmet>
                <title>{t("title")} | {model.name} | {t("translation:nav.brand")}</title>
            </Helmet>

            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}

            <Card sx={{mt: 0.1, mb: 5, '&.MuiPaper-root.MuiCard-root': {borderRadius: 0}}}>
                <CardMedia component="div" sx={{
                    backgroundImage: model.cover ? "url(" + Utils.getApiUrl() + "/shop/" + model.cover
                        + "?" + Math.random() + ")" : "url(/img/banner.jpg)"
                }}>
                    <Box component="div" sx={{
                        backgroundColor: 'rgba(0, 82, 73, 0.8)',
                        backdropFilter: 'blur(2px)',
                        color: theme.palette.common.white,
                        py: 5,
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Avatar src={model.logo && Utils.getApiUrl() + "/shop/" + model.logo
                            + "?" + Math.random()} alt={model.name} sx={{
                            bgcolor: theme.palette.primary.main,
                            width: 124,
                            height: 124,
                            mb: 1,
                            border: '2px solid white',
                            [theme.breakpoints.down('sm')]: {width: 76, height: 76},
                        }}>
                            <Store fontSize='large'/>
                        </Avatar>
                        <Typography variant="h5" noWrap gutterBottom>{model.name}</Typography>
                    </Box>
                </CardMedia>
                <CardActions sx={{justifyContent: 'center', pb: 0}}>
                    <Tabs component="nav" value={values.selectedTab} onChange={handleTabChange} variant="scrollable"
                          scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab icon={<HomeIcon fontSize='small'/>} iconPosition="start" component={RouterLink}
                             label={t("translation:nav.home")}
                             to={"/shop/" + id} sx={{fontSize: "1rem", minHeight: 0}}/>
                        <Tab icon={<AutoAwesome fontSize='small'/>} iconPosition="start" component={RouterLink}
                             label={t("translation:nav.product")}
                             to={"/shop/" + id + "/products"} sx={{fontSize: "1rem", minHeight: 0}}/>
                        <Tab icon={<SettingsAccessibility fontSize='small'/>} iconPosition="start"
                             component={RouterLink} label={t("translation:nav.story")}
                             to={"/shop/" + id + "/stories"} sx={{fontSize: "1rem", minHeight: 0}}/>
                    </Tabs>
                </CardActions>
            </Card>

            <Container component='main'>
                <Typography variant="h4" sx={{my: 5}}>{t("title")}</Typography>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                    <OutlinedInput name="name"
                                   startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
                                   placeholder={t("search")} size='small' sx={{fontWeight: 'fontWeightBold'}}
                                   value={filter.name} onChange={handleFilterChange}/>
                </Stack>
                {values.message && (
                    <Alert severity="error">{values.message}</Alert>
                )}

                <Grid container spacing={3} sx={{my: 5}}>
                    {values.page && values.page.content && values.page.content.map(entity =>
                        <Grid key={entity.id} item xs={6} sm={4} md={3}>
                            <Card>
                                <CardActionArea component={RouterLink} to={"/product/" + entity.id}>
                                    <CardMedia component="img" height="150" alt={entity.name} image={entity.pictures ?
                                        Utils.getApiUrl() + "/product/" + entity.id + "/picture/" + (entity.pictures.split(',')[0])
                                        : "/img/banner.jpg"}/>
                                    <CardContent>
                                        <Typography variant="subtitle2" gutterBottom noWrap>{entity.name}</Typography>
                                        <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                            {entity.price && (<><NumericFormat value={entity.price} displayType={'text'}
                                                                               thousandSeparator prefix={'¥'}/></>)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                {!values.loading && values.page.totalElements === 0 &&
                    <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                                color="text.secondary">
                        <FindInPage fontSize='large'/> {t("translation:view.empty")}
                    </Typography>}
                {!values.loading && values.page.totalElements !== 0 && <>
                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={5}>
                        <Pagination count={values.page.totalPages} variant="outlined" color="primary"
                                    onChange={gotoPage}/>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
                        <Typography>{t("translation:view.total", {
                            totalElements: values.page.totalElements,
                            pageNumber: values.page.number,
                            totalPages: values.page.totalPages
                        })}</Typography>
                    </Stack>
                </>}
            </Container>
        </>
    );
};

export default ShopProducts;
