import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Alert,
    Box,
    Breadcrumbs,
    Container,
    IconButton,
    InputAdornment,
    LinearProgress,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {AutoAwesome, Bookmark, CurrencyYuan, Dashboard as DashboardIcon, Delete, MoreVert} from "@mui/icons-material";
import {NumericFormat} from "react-number-format";
import PropTypes from "prop-types";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@victz/ckeditor5-build-classic';
import "@victz/ckeditor5-build-classic/build/translations/zh.js"
import SecurityUtils from "../component/SecurityUtils";
import Utils from "../component/Utils";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const {onChange, ...other} = props;

    return (
        <NumericFormat {...other} getInputRef={ref} thousandSeparator isNumericString onValueChange={(values) => {
            onChange({target: {name: props.name, value: values.value}});
        }}/>
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ProductHome = (props) => {

    const {shopId} = useParams();
    const {id} = useParams();
    const {t, i18n} = useTranslation(['product', 'admin']);

    const navigate = useNavigate();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        nameError: false,
        nameValidate: '',
        anchorEl: null,
        selectedPicture: ''
    });

    const [model, setModel] = useState({
        id: '',
        shop: undefined,
        name: '',
        price: '',
        description: '',
        pictures: '',
        attachments: '',
        published: false
    });

    const [editor, setEditor] = useState();
    const [pictures, setPictures] = useState({
        uploading: false,
        progress: 0
    });
    const [lightbox, setLightbox] = useState({
        pictures: [],
        isOpen: false,
        index: 1
    });

    useEffect(() => {
        if (id) {
            console.log("id:" + id);
            axios.get(Utils.getApiUrl() + "/home/product/" + id, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        for (const [name, value] of Object.entries(response.data)) {
                            if (model.hasOwnProperty(name)) {
                                setModel((values) => ({
                                    ...values, [name]: value
                                }));
                            }
                        }
                        setValues((values) => ({
                            ...values, loading: false
                        }))
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else if (shopId) {
            console.log("shopId:" + shopId);
            axios.get(Utils.getApiUrl() + "/home/shop/" + shopId, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        setModel((values) => ({
                            ...values, shop: response.data
                        }));
                        setValues((values) => ({
                            ...values, loading: false
                        }))
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            setValues((values) => ({
                ...values, loading: false
            }))
        }
    }, []);

    useEffect(() => {
        if (!id && model.id && model.id.trim().length > 0) {
            axios.put(Utils.getApiUrl() + "/home/product", model, {headers: SecurityUtils.authHeader()}).then((response) => {
                    console.log(response);
                    navigate('/home/product/' + model.id);
                    window.location.reload();
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, loading: false, message: error.response.data.message
                    }));
                }
            );
        }
    }, [model.id]);

    useEffect(() => {
        let pictureArray = [];
        model.pictures.split(",").map(picture => {
            pictureArray.push("/product/" + model.id + "/picture/" + picture);
        })
        setLightbox((values) => ({...values, pictures: pictureArray}))
    }, [model.pictures]);

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setModel((values) => ({
            ...values, [name]: value
        }));
        if (name === "name") {
            if (value.trim().length > 1) {
                setValues((values) => ({
                    ...values, nameError: false, nameValidate: ''
                }));
            } else {
                setValues((values) => ({
                    ...values, nameError: true, nameValidate: t("product:nameValidate")
                }));
            }
        }
    };

    const loadPictureThumbnail = (picture) => {
        let index = picture.lastIndexOf(".");
        let fileNameExt = picture.substring(index);
        let fileNameWithoutExt = picture.substring(0, index);
        return Utils.getApiUrl() + "/product/" + model.id + "/picture/" + fileNameWithoutExt + "_thumbnail" + fileNameExt;
    }

    const pictureMenuOpen = (event, picture) => {
        setValues((values) => ({
            ...values, anchorEl: event.currentTarget, selectedPicture: picture
        }))
    };

    const pictureMenuClose = () => {
        setValues((values) => ({
            ...values, anchorEl: null, selectedPicture: ''
        }))
    };

    const setPictureCover = (picture) => {
        if (!model.pictures.startsWith(picture)) {
            setModel((values) => ({
                ...values, pictures: picture + "," + model.pictures.replace("," + picture, "")
            }))
        }
        pictureMenuClose();
    }

    const handlePictureRemove = async (picture) => {
        setPictures((values) => ({
            ...values, uploading: true
        }));
        await axios.delete(Utils.getApiUrl() + "/home/product/" + model.id + "/picture/" + picture, {headers: SecurityUtils.authHeader()})
            .then((response) => {
                    console.log(response);
                    setModel((values) => ({
                        ...values, pictures: response.data.pictures
                    }));
                }, error => {
                    console.log(error);
                    setValues((values) => ({
                        ...values, message: error.response.data.message
                    }));
                }
            ).finally(() => {
                setPictures((values) => ({
                    ...values, uploading: false
                }));
            });
        pictureMenuClose();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!(event.target.elements["name"].value.trim().length > 1)) {
            setValues((values) => ({
                ...values, nameError: true, nameValidate: t("product:nameValidate")
            }));
            return false;
        }
        setValues((values) => ({
            ...values, loading: true
        }));
        if (model.id && model.id.trim().length > 0) {
            await axios.put(Utils.getApiUrl() + "/home/product", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        navigate('/home/products/shop/' + model.shop.id);
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            await axios.post(Utils.getApiUrl() + "/home/product", model, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        navigate('/home/products/shop/' + model.shop.id);
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        }
    }

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("admin:edit")} | {t("product:title")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" gutterBottom>{t("product:title")} | {t("admin:edit")}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator="&bull;" fontSize='small'>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"
                      sx={{display: 'flex', alignItems: 'center'}}>
                    <DashboardIcon sx={{mr: 0.5}} fontSize="inherit"/> {t("translation:nav.dashboard")}
                </Link>
                <Link component={RouterLink} underline="hover" color="text.secondary" to="/home"
                      sx={{display: 'flex', alignItems: 'center'}}>
                    <AutoAwesome sx={{mr: 0.5}} fontSize="inherit"/> {t("product:title")}
                </Link>
                <Typography fontSize='inherit'> {t("admin:edit")} </Typography>
            </Breadcrumbs>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mx: "auto", my: 5}}>

                {values.message && (
                    <Alert severity="error">{values.message}</Alert>
                )}

                <TextField name="name" label={t("product:name")} margin="normal" fullWidth required autoFocus
                           value={model.name} onChange={handleChange} error={values.nameError}
                           helperText={values.nameValidate}/>
                <TextField name="price" label={t("product:price")} margin="normal" value={model.price}
                           onChange={handleChange}
                           InputProps={{
                               inputComponent: NumericFormatCustom,
                               startAdornment: (<InputAdornment position="start">¥</InputAdornment>)
                           }}/>
                <CKEditor id="editor" editor={ClassicEditor} data={model.description || ''} value=""
                          onReady={(editor) => {
                              setEditor(editor);
                          }}
                          config={{
                              language: i18n.language || window.localStorage.i18nextLng,
                              simpleImageUpload: {
                                  onUpload: file => {
                                      return new Promise((resolve, reject) => {
                                          if (file.size > 1024 * 1024 * 100) {
                                              setValues((values) => ({
                                                  ...values, message: t("product:uploadValidateSize")
                                              }));
                                              reject(t("product:uploadValidateSize"));
                                              return;
                                          }
                                          let formData = new FormData();
                                          formData.append("pictures", file);
                                          if (id) {
                                              formData.set("id", id);
                                          }
                                          setPictures((values) => ({
                                              ...values, uploading: true
                                          }));

                                          axios.post(Utils.getApiUrl() + "/home/product/pictures", formData, {
                                              headers: {
                                                  "Authorization": SecurityUtils.getBearerToken(),
                                                  "Content-Type": "multipart/form-data"
                                              }, processData: false, contentType: false,
                                              onUploadProgress: progressEvent => console.log(progressEvent.loaded)
                                          }).then((response) => {
                                                  console.log(response);
                                                  setModel((values) => ({
                                                      ...values, id: response.data.id, pictures: response.data.pictures
                                                  }));
                                                  let pictureArray = response.data.pictures.split(",");
                                                  resolve(Utils.getApiUrl() + "/product/" + response.data.id + "/picture/" + pictureArray[pictureArray.length - 1]);
                                              }, error => {
                                                  console.log(error);
                                                  setValues((values) => ({
                                                      ...values, message: error.message
                                                  }));
                                                  reject(error.message);
                                                  return;
                                              }
                                          ).finally(() => {
                                              setPictures((values) => ({
                                                  ...values, uploading: false
                                              }));
                                          });
                                      });
                                  }
                              }
                          }}
                          onChange={(event, editor) => {
                              const data = editor.getData();
                              setModel((values) => ({
                                  ...values, description: data
                              }));
                          }}
                />
                {model.pictures && <Paper sx={{
                    my: 1,
                    p: 1,
                    whiteSpace: 'nowrap',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    ":-webkit-overflow-scrolling": 'touch',
                    border: 1,
                    borderColor: '#ced4da',
                    borderRadius: 1
                }}>
                    {model.pictures.split(",").map((picture, index) =>
                        <Box key={index} sx={{position: "relative", display: "inline-block", float: "none", mx: .5}}>
                            <img src={loadPictureThumbnail(picture)} height='150'
                                 onClick={() => setLightbox((values) => ({
                                     ...values,
                                     isOpen: !lightbox.isOpen,
                                     index: index
                                 }))}/>
                            {index === 0 && <Bookmark color='warning' fontSize='large'
                                                      sx={{position: "absolute", top: 0, left: 0}}/>}
                            <IconButton color='primary' sx={{position: "absolute", top: 0, right: 0}}
                                        onClick={(event) => pictureMenuOpen(event, picture)}><MoreVert/></IconButton>
                        </Box>
                    )}
                </Paper>}
                <Grid container columnSpacing={2}>
                    <Grid xs={12} sm={6} smOffset={6}>
                        <LoadingButton type="submit" variant="contained" loading={values.loading} fullWidth
                                       sx={{my: 3}}>{t("admin:save")}</LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            <Menu open={Boolean(values.anchorEl)} anchorEl={values.anchorEl}
                  onClose={pictureMenuClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  PaperProps={{
                      elevation: 0, sx: {
                          width: 180,
                          maxWidth: '100%',
                          overflow: 'visible',
                          mt: 1,
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 15,
                              width: 15,
                              height: 15,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          }
                      },
                  }}>
                <MenuItem sx={{color: 'text.secondary'}} onClick={() => setPictureCover(values.selectedPicture)}>
                    <ListItemIcon><Bookmark/></ListItemIcon>
                    <ListItemText primary={t("product:setAsCover")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
                <MenuItem sx={{color: 'text.secondary'}} onClick={() => handlePictureRemove(values.selectedPicture)}>
                    <ListItemIcon><Delete/></ListItemIcon>
                    <ListItemText primary={t("admin:delete")} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>
        </Container>
    );
};
export default ProductHome;
