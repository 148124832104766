import React, {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    InputAdornment,
    LinearProgress,
    OutlinedInput,
    Pagination,
    Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import Utils from "./component/Utils";
import {Helmet} from "react-helmet-async";
import {FindInPage, Search, Store} from "@mui/icons-material";
import SecurityUtils from "./component/SecurityUtils";
import Grid from "@mui/material/Unstable_Grid2";
import {useTheme} from "@mui/material/styles";
import {format, parseISO} from "date-fns";

const Shops = (props) => {

    const {t} = useTranslation('shop');
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        params: '',
        page: undefined
    });

    useEffect(() => {
        loadPage(location.pathname);
        setValues((values) => ({
            ...values, params: location.pathname
        }));
    }, [location.pathname]);

    const loadPage = async (params) => {
        await axios.get(Utils.getApiUrl() + params, {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, page: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    };

    const gotoPage = (event, number) => {
        let params = values.params;
        params = Utils.buildParams(params, "/page/", number);
        console.log(params);
        navigate(params);
    };

    const [filter, setFilter] = useState({
        name: ""
    })

    const handleFilterChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setFilter((values) => ({
            ...values, [name]: value,
        }));
    };

    useEffect(() => {
        if (!values.params) return;
        const timeOutId = setTimeout(() => {
            let params = values.params;
            params = Utils.buildParams(params, "/name/", filter.name);
            loadPage(params);
            setValues((values) => ({
                ...values, params: params
            }));
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filter.name]);

    return (
        <Container component='main'>
            <Helmet>
                <title>{t("translation:nav.shop")} | {t("translation:nav.brand")}</title>
            </Helmet>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Typography variant="h4" sx={{my: 5}}>{t("translation:nav.shop")}</Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                <OutlinedInput name="name" startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
                               placeholder={t("search")} size='small' sx={{fontWeight: 'fontWeightBold'}}
                               value={filter.name} onChange={handleFilterChange}/>
            </Stack>
            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            <Grid container spacing={3} sx={{my: 5}}>
                {values.page && values.page.content && values.page.content.map(entity =>
                    <Grid key={entity.id} item xs={12} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/shop/" + entity.id}>
                                <CardMedia component="div" sx={{
                                    height: 200,
                                    backgroundImage: entity.cover ? "url(" + Utils.getApiUrl() + "/shop/" + entity.cover
                                        + "?" + Math.random() + ")" : "url(/img/banner.jpg)",
                                    display: 'flex',
                                    alignItems: "flex-end",
                                    justifyContent: 'center'
                                }}>
                                    <Box component="span" sx={{
                                        display: 'inline-block',
                                        color: 'background.paper',
                                        bgcolor: 'currentColor',
                                        mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        position: 'absolute',
                                        left: 'calc(100% * 1 /2) - 100',
                                        top: 164,
                                        width: 144,
                                        height: 62
                                    }}/>
                                    <Avatar src={entity.logo && Utils.getApiUrl() + "/shop/" + entity.logo
                                        + "?" + Math.random()} alt={entity.name} sx={{
                                        bgcolor: theme.palette.primary.main, width: 64, height: 64, mb: -4
                                    }}>
                                        <Store fontSize='large'/>
                                    </Avatar>
                                </CardMedia>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom>{entity.name}</Typography>
                                    <Typography variant="caption" noWrap gutterBottom
                                                sx={{color: 'text.disabled', display: 'block'}}>
                                        {format(parseISO(entity.createdDate), 'yyyy-MM-dd')}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {!values.loading && values.page.totalElements === 0 &&
                <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                            color="text.secondary">
                    <FindInPage fontSize='large'/> {t("translation:view.empty")}
                </Typography>}
            {!values.loading && values.page.totalElements !== 0 && <>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={5}>
                    <Pagination count={values.page.totalPages} variant="outlined" color="primary" onChange={gotoPage}/>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
                    <Typography>{t("translation:view.total", {
                        totalElements: values.page.totalElements,
                        pageNumber: values.page.number,
                        totalPages: values.page.totalPages
                    })}</Typography>
                </Stack>
            </>}
        </Container>
    );
};

export default Shops;
