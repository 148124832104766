import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import SecurityUtils from "./SecurityUtils";

const PrivateRoute = ({children}) => {

    const user = SecurityUtils.getCurrentUser();
    const {pathname} = useLocation();
    return user ? children : <Navigate to='/login' state={{from: pathname}} replace/>;
};
export default PrivateRoute;
