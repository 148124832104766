import axios from "axios";
import Utils from "./Utils";

class SeucrityUtils {
    login(login, password, rememberme) {
        return axios
            .post(Utils.getApiUrl() + "/login", {login, password, rememberme})
            .then(response => {
                if (response.data.accessToken) {
                    sessionStorage.setItem("user", JSON.stringify(response.data));
                    if (rememberme) {
                        localStorage.setItem("user", JSON.stringify(response.data));
                    }
                }
                return response.data;
            });
    }

    logout() {
        sessionStorage.removeItem("user");
        localStorage.removeItem("user");
    }

    refreshToken() {

        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            axios.post(Utils.getApiUrl() + "/refresh-token/true", null, {headers: this.authHeader()})
                .then(response => {
                    if (response.data.accessToken) {
                        localStorage.setItem("user", JSON.stringify(response.data));
                    }
                });
        } else if (JSON.parse(sessionStorage.getItem('user'))) {
            axios.post(Utils.getApiUrl() + "/refresh-token", null, {headers: this.authHeader()})
                .then(response => {
                    if (response.data.accessToken) {
                        sessionStorage.setItem("user", JSON.stringify(response.data));
                    }
                });
        }
    }


    register(name, email, password) {
        return axios.post(Utils.getApiUrl() + "/register", {name, email, password});
    }

    getCurrentUser() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            return user;
        } else {
            return JSON.parse(localStorage.getItem('user'));
        }
    }

    authHeader() {
        const user = this.getCurrentUser();
        if (user && user.accessToken) {
            return {'Authorization': 'Bearer ' + user.accessToken};
        } else {
            return {};
        }
    }

    getBearerToken() {
        const user = this.getCurrentUser();
        if (user && user.accessToken) {
            return "Bearer " + user.accessToken;
        } else {
            return "";
        }
    }
}

export default new SeucrityUtils();
