import React, {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    LinearProgress,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import axios from "axios";
import SecurityUtils from "./component/SecurityUtils";
import Utils from "./component/Utils";
import Grid from "@mui/material/Unstable_Grid2";
import {NumericFormat} from "react-number-format";
import {ReadMore, Store} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {format, parseISO} from "date-fns";

const Welcome = (props) => {

    const {t} = useTranslation('index');
    const theme = useTheme();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        pageShops:  undefined,
        pageProducts:  undefined
    });

    useEffect(() => {
        axios.get(Utils.getApiUrl() + "/shops/size/12", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, pageShops: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
        axios.get(Utils.getApiUrl() + "/products/size/12", {headers: SecurityUtils.authHeader()}).then((response) => {
                console.log(response);
                setValues((values) => ({
                    ...values, loading: false, pageProducts: response.data
                }));
            }, error => {
                console.log(error);
                setValues((values) => ({
                    ...values, loading: false, message: error.response.data.message
                }));
            }
        );
    }, []);


    return (
        <Container component='main'>
            {values.loading && <LinearProgress color="primary" sx={{my: 1}}/>}
            <Paper elevation={8} sx={{
                my: 5,
                p: 5,
                background: `url(/img/banner.jpg) center center no-repeat`,
                backgroundSize: 'cover',
                color: 'white',
                textAlign: 'center'
            }}>
                <Typography variant="h4">{t("title")}</Typography>
            </Paper>

            {values.message && (
                <Alert severity="error">{values.message}</Alert>
            )}

            {values.pageShops && values.pageShops.content && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                    <Typography variant="h4" gutterBottom>{t("translation:nav.shop")}</Typography>
                    <Button component={RouterLink} to="/shops"
                            endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                </Stack></>}
            <Grid container spacing={3} sx={{my: 5}}>
                {values.pageShops && values.pageShops.content && values.pageShops.content.map(entity =>
                    <Grid key={entity.id} item xs={12} sm={6} md={4}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/shop/" + entity.id}>
                                <CardMedia component="div" sx={{
                                    height: 200,
                                    backgroundImage: entity.cover ? "url(" + Utils.getApiUrl() + "/shop/" + entity.cover
                                        + "?" + Math.random() + ")" : "url(/img/banner.jpg)",
                                    display: 'flex',
                                    alignItems: "flex-end",
                                    justifyContent: 'center'
                                }}>
                                    <Box component="span" sx={{
                                        display: 'inline-block',
                                        color: 'background.paper',
                                        bgcolor: 'currentColor',
                                        mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                        position: 'absolute',
                                        left: 'calc(100% * 1 /2) - 100',
                                        top: 164,
                                        width: 144,
                                        height: 62
                                    }}/>
                                    <Avatar src={entity.logo && Utils.getApiUrl() + "/shop/" + entity.logo
                                        + "?" + Math.random()} alt={entity.name} sx={{
                                        bgcolor: theme.palette.primary.main, width: 64, height: 64, mb: -4
                                    }}>
                                        <Store fontSize='large'/>
                                    </Avatar>
                                </CardMedia>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom>{entity.name}</Typography>
                                    <Typography variant="caption" noWrap gutterBottom
                                                sx={{color: 'text.disabled', display: 'block'}}>
                                        {format(parseISO(entity.createdDate), 'yyyy-MM-dd')}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {values.pageProducts && values.pageProducts.content && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                    <Typography variant="h4" gutterBottom>{t("translation:nav.product")}</Typography>
                    <Button component={RouterLink} to="/products"
                            endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                </Stack></>}
            <Grid container spacing={3} sx={{mb: 5}}>
                {values.pageProducts && values.pageProducts.content && values.pageProducts.content.map(entity =>
                    <Grid key={entity.id} item xs={6} sm={4} md={3}>
                        <Card>
                            <CardActionArea component={RouterLink} to={"/product/" + entity.id}>
                                <CardMedia component="img" height="150" alt={entity.name} image={entity.pictures ?
                                    Utils.getApiUrl() + "/product/" + entity.id + "/picture/" + (entity.pictures.split(',')[0])
                                    : "/img/banner.jpg"}/>
                                <Box component="span" sx={{
                                    display: 'inline-block',
                                    color: 'background.paper',
                                    bgcolor: 'currentColor',
                                    mask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    WebkitMask: `url(/img/avatar_shape.svg) no-repeat center / contain`,
                                    position: 'absolute',
                                    left: 0,
                                    top: 130,
                                    width: 80,
                                    height: 36
                                }}/>
                                <Avatar src={entity.shop && entity.shop.logo && Utils.getApiUrl() + "/shop/" + entity.shop.logo
                                    + "?" + Math.random()} alt={entity.shop && entity.shop.name} sx={{
                                    position: 'absolute',
                                    left: theme.spacing(3),
                                    top: 135,
                                    width: 32,
                                    height: 32,
                                    bgcolor: theme.palette.primary.main
                                }}>
                                    <Store/>
                                </Avatar>
                                <CardContent sx={{pt: 4}}>
                                    <Typography variant="subtitle2" gutterBottom noWrap>{entity.name}</Typography>
                                    <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                        {entity.price && (<><NumericFormat value={entity.price} displayType={'text'}
                                                                           thousandSeparator prefix={'¥'}/></>)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default Welcome;
