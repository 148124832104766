import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    LinearProgress,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import axios from "axios";
import SecurityUtils from "../component/SecurityUtils";
import {useTheme} from "@mui/material/styles";
import {Alert} from "@mui/lab";
import Grid from "@mui/material/Unstable_Grid2";
import {NumericFormat} from "react-number-format";
import {AutoAwesome, FindInPage, Home as HomeIcon, ReadMore, SettingsAccessibility} from "@mui/icons-material";
import Utils from "../component/Utils";

const User = (props) => {

    const {username} = useParams();
    const {t} = useTranslation('user');
    const theme = useTheme();

    const [values, setValues] = useState({
        loading: true,
        message: '',
        selectedTab: 0,
        pageLatest: undefined,
        pageMostViewed: undefined
    });

    const [model, setModel] = useState({
        id: '',
        name: '',
        username: '',
        photo: '',
        cover: ''
    });

    useEffect(() => {
        if (username) {
            axios.get(Utils.getApiUrl() + "/user/" + username, {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        for (const [name, value] of Object.entries(response.data)) {
                            if (model.hasOwnProperty(name)) {
                                setModel((values) => ({
                                    ...values, [name]: value
                                }));
                            }
                        }
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, message: error.response.data.message
                        }));
                    }
                );
            axios.get(Utils.getApiUrl() + "/user/" + username + "/products/size/4", {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        setValues((values) => ({
                            ...values, pageLatest: response.data
                        }));
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, message: error.response.data.message
                        }));
                    }
                );
            axios.get(Utils.getApiUrl() + "/user/" + username + "/products/size/4/sort/views,desc", {headers: SecurityUtils.authHeader()})
                .then((response) => {
                        console.log(response);
                        setValues((values) => ({
                            ...values, loading: false, pageMostViewed: response.data
                        }));
                    }, error => {
                        console.log(error);
                        setValues((values) => ({
                            ...values, loading: false, message: error.response.data.message
                        }));
                    }
                );
        } else {
            setValues((values) => ({
                ...values, loading: false
            }))
        }
    }, []);

    const handleTabChange = (event, tab) => {
        setValues((values) => ({
            ...values, selectedTab: tab
        }))
    };

    return (<>

            <Helmet>
                <title>{model.name} | {t("translation:nav.brand")}</title>
            </Helmet>

            {values.loading && <LinearProgress color="primary"/>}

            <Card sx={{mt: 0.1, mb: 5, '&.MuiPaper-root.MuiCard-root': {borderRadius: 0}}}>
                <CardMedia component="div" sx={{
                    backgroundImage: model.cover ? "url(" + Utils.getApiUrl() + "/user/" + model.cover
                        + "?" + Math.random() + ")" : "url(/img/banner.jpg)"
                }}>
                    <Box component="div" sx={{
                        backgroundColor: 'rgba(0, 82, 73, 0.3)',
                        color: theme.palette.common.white,
                        py: 5,
                        display: 'flex',
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Avatar src={model.photo && Utils.getApiUrl() + "/user/" + model.photo
                            + "?" + Math.random()} alt={model.name} sx={{
                            bgcolor: theme.palette.primary.main,
                            width: 124,
                            height: 124,
                            mb: 1,
                            border: '2px solid white',
                            [theme.breakpoints.down('sm')]: {width: 76, height: 76},
                        }}/>
                        <Typography variant="h5" noWrap gutterBottom>{model.name}</Typography>
                    </Box>
                </CardMedia>
                <CardActions sx={{justifyContent: 'center', pb: 0}}>
                    <Tabs component="nav" value={values.selectedTab} onChange={handleTabChange} variant="scrollable"
                          scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab icon={<HomeIcon fontSize='small'/>} iconPosition="start" component={RouterLink}
                             label={t("translation:nav.home")}
                             to={"/user/" + username} sx={{fontSize: "1rem", minHeight: 0}}/>
                        <Tab icon={<AutoAwesome fontSize='small'/>} iconPosition="start" component={RouterLink}
                             label={t("translation:nav.product")}
                             to={"/user/" + username + "/products"} sx={{fontSize: "1rem", minHeight: 0}}/>
                        <Tab icon={<SettingsAccessibility fontSize='small'/>} iconPosition="start"
                             component={RouterLink} label={t("translation:nav.story")}
                             to={"/user/" + username + "/stories"} sx={{fontSize: "1rem", minHeight: 0}}/>
                    </Tabs>
                </CardActions>
            </Card>

            <Container component='main'>

                {values.message && (
                    <Alert severity="error">{values.message}</Alert>
                )}

                {values.pageLatest && values.pageLatest.content && <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                        <Typography variant="h4" gutterBottom>{t("translation:view.latest")}</Typography>
                        <Button component={RouterLink} to={"/user/" + username + "/products"}
                                endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                    </Stack></>}
                {!values.loading && values.pageLatest.totalElements === 0 &&
                    <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                                color="text.secondary">
                        <FindInPage fontSize='large'/> {t("translation:view.empty")}
                    </Typography>}
                <Grid container spacing={3} sx={{mb: 5}}>
                    {values.pageLatest && values.pageLatest.content && values.pageLatest.content.map(product =>
                        <Grid key={product.id} item xs={6} sm={4} md={3}>
                            <Card>
                                <CardActionArea component={RouterLink} to={"/product/" + product.id}>
                                    <CardMedia component="img" height="150" image={product.pictures ?
                                        Utils.getApiUrl() + "/product/" + product.id + "/picture/" + (product.pictures.split(',')[0]).replace('.', '_thumbnail.')
                                        : "/img/banner.jpg"}
                                    />
                                    <CardContent>
                                        <Typography variant="subtitle2" gutterBottom noWrap>{product.name}</Typography>
                                        <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                            {product.price && (<><NumericFormat value={product.price}
                                                                                displayType={'text'} thousandSeparator
                                                                                prefix={'¥'}/></>)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                {values.pageMostViewed && values.pageMostViewed.content && <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" my={3}>
                        <Typography variant="h4" gutterBottom>{t("translation:view.mostViewed")}</Typography>
                        <Button component={RouterLink} to={"/user/" + username + "/products"}
                                endIcon={<ReadMore/>}>{t("translation:view.all")}</Button>
                    </Stack></>}
                {!values.loading && values.pageMostViewed.totalElements === 0 &&
                    <Typography variant='h3' align='center' justifyContent="center" alignItems="center" my={5}
                                color="text.secondary">
                        <FindInPage fontSize='large'/> {t("translation:view.empty")}
                    </Typography>}
                <Grid container spacing={3} sx={{mb: 5}}>
                    {values.pageMostViewed && values.pageMostViewed.content && values.pageMostViewed.content.map(product =>
                        <Grid key={product.id} item xs={6} sm={4} md={3}>
                            <Card>
                                <CardActionArea component={RouterLink} to={"/product/" + product.id}>
                                    <CardMedia component="img" height="150" image={product.pictures ?
                                        Utils.getApiUrl() + "/product/" + product.id + "/picture/" + (product.pictures.split(',')[0]).replace('.', '_thumbnail.')
                                        : "/img/banner.jpg"}
                                    />
                                    <CardContent>
                                        <Typography variant="subtitle2" gutterBottom noWrap>{product.name}</Typography>
                                        <Typography color="primary" sx={{fontWeight: 'fontWeightBold'}}>
                                            {product.price && (<><NumericFormat value={product.price}
                                                                                displayType={'text'} thousandSeparator
                                                                                prefix={'¥'}/></>)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}
                </Grid>

            </Container>
        </>
    );
};
export default User;
